import { mapActions } from "vuex";
import * as types from "../../../../store/types";
import userInfoService from "../../../../services/userInfoService";
import currenciesService from "../../../../services/currenciesService";
import transactionsService from "../../../../services/transactionsService";

const latestTransactions = () =>
    import("../../../../components/dashboardTransactions/index.vue");
const dashboardChart = () =>
    import("../../../../components/dashboardCharts/index.vue");
const dashboardCurrencies = () =>
    import("../../../../components/dashboardCurrencies/index.vue");
const dashboardWallet = () =>
    import("../../../../components/dashboardWallet/index.vue");
const dashboardMerchantInfo = () =>
    import("../../../../components/dashboardMerchantInfo/index.vue");

export default {
    name: "dashboard",
    components: {
        latestTransactions,
        dashboardChart,
        dashboardCurrencies,
        dashboardWallet,
        dashboardMerchantInfo,
    },
    props: [],
    data() {
        return {};
    },
    computed: {},
    mounted() { },
    created() {
        this.getInfoData();
        this.getCurrenciesFunc();
        this.getTransactionsData();
    },
    //! just for resend request for other languages
    /*  beforeRouteUpdate(to, from, next) {
        if (to.params.lang) {
            console.log("change");
            next();
        }
    }, */
    methods: {
        ...mapActions({
            userInfoAction: types.ACTION_USER_INFO,
            currenciesAction: types.ACTION_CURRENCIES,
            transactionsAction: types.ACTION_TRANSACTIONS,
        }),
        getCurrenciesFunc() {
            currenciesService
                .getCurrencies()
                .then((response) => {
                    this.currenciesAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        getTransactionsData() {
            transactionsService
                .getTransactions(localStorage.getItem("bitrahAccessToken"), 0)
                .then((response) => {
                    this.transactionsAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        getInfoData() {
            userInfoService
                .getUserInfo()
                .then((response) => {
                    this.userInfoAction(response.data);
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                })
                .finally(() => (this.loading = false));
        },
    },
};
